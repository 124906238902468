import React, { useRef, useState } from 'react'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Dolphin from '../../components/svg/dolphin'
import { PlayCircle, PauseCircle } from 'react-feather'
import MLine from '../svg/m-line'
import SLine from '../svg/s-line'
import XLine from '../svg/x-line'
import FusionLine from '../svg/fusion-line'
import O3Line from '../svg/o3-line'
import Wave from '../svg/wave'
import Swash from '../svg/swash-line'
import Liberty from '../svg/liberty-line'
import ELine from '../svg/e-line'
import {
	HeroSection,
	HeroItemsContainer,
	Line,
	HeroButtons,
	HeroSeeAllLink,
	HeroSeeAll,
	HeroBookLink,
	HeroBook,
	Bold,
	Heading1,
	BannerText,
	HeroBottomText
} from './styles'

const heroBannerOptions = {
	renderMark: {
		[MARKS.BOLD]: text => <Bold>{text}</Bold>,
	},
	renderNode: {
		[BLOCKS.HEADING_1]: (node, children) => <Heading1>{children}</Heading1>,
		[BLOCKS.PARAGRAPH]: (node, children) => <BannerText>{children}</BannerText>,
	},
}

const Hero = ({
	mainActionText,
	mainActionUrl,
	secondActionText,
	secondActionUrl,
	description,
	videourl,
	image,
	seriesName,
	bannerSources,
	active,
	locale
}) => {
	const [control, setControl] = useState(true)
	const play = useRef(null)
	const playVidHandler = () => {
		setControl(false)
		play.current.play()
	}
	const pauseVidHandler = () => {
		setControl(true)
		play.current.pause()
	}
	return (
		<HeroSection sources={bannerSources}>
			<video ref={play} width="100%" height="100%" loop>
				<source src={videourl?.file?.url} type="video/mp4"></source>
			</video>
			<HeroItemsContainer sources={control === true ? bannerSources : false}>
				{control ? (
					<>
						{image === null ? (
							<Dolphin width="400px" height="250px" />
						) : (
							<img
								className="brandImage"
								src={image.file.url}
								alt={image.description}
							/>
						)}
						<Line>
							{seriesName === 'M Line' ? (
								<MLine width="100%" height="100%" />
							) : seriesName === 'S Line' ? (
								<SLine width="100%" height="100%" />
							) : seriesName === 'X Line' ? (
								<XLine width="100%" height="100%" />
							) : seriesName === 'Fusion Line' ? (
								<FusionLine width="100%" height="100%" />
							) : seriesName === 'O₃ Line' ? (
								<O3Line width="100%" height="100%" />
							) : seriesName === 'Wave Line' ? (
								<Wave width="100%" height="100%" />
							) : seriesName === 'Swash Line' ? (
								<Swash width="100%" height="100%" />
							) : seriesName === 'Liberty Line' ? (
								<Liberty width="100%" height="100%" />
							) : seriesName === 'E Line' ? (
								<ELine width="100%" height="100%" />
							) : (
								''
							)}
						</Line>
						{documentToReactComponents(description.json, heroBannerOptions)}
						<HeroButtons>
							{
								active === null || active === true ? (
									<>
										{mainActionUrl != undefined || mainActionUrl != null ? (
											locale === 'en-AU' ? ( // temporary
												<HeroSeeAllLink to={'/' + mainActionUrl}>
													{mainActionText != undefined || mainActionText != null ? (
														<HeroSeeAll>{mainActionText}</HeroSeeAll>
													) : (
														''
													)}
												</HeroSeeAllLink>
											) : (
												<HeroSeeAllLink to={mainActionUrl}>
													{mainActionText != undefined || mainActionText != null ? (
														<HeroSeeAll>{mainActionText}</HeroSeeAll>
													) : (
														''
													)}
												</HeroSeeAllLink>
											)
										) : (
											''
										)}
										{secondActionUrl != undefined || secondActionUrl != null ? (
											<HeroBookLink to={'/' + secondActionUrl}>
												{secondActionText != undefined || secondActionText != null ? (
													<HeroBook>{secondActionText}</HeroBook>
												) : (
													''
												)}
											</HeroBookLink>
										) : (
											''
										)}
									</>
								) : (
								<HeroSeeAllLink to={seriesName === 'X Line'? `${mainActionUrl}` :`/support/contact/?subject=${seriesName}`}>
									<HeroSeeAll>{seriesName === 'X Line' ? 'buy now' : "enquire now"}</HeroSeeAll>
								</HeroSeeAllLink>
								)
							}
						</HeroButtons>
					</>
				) : (
					''
				)}
				{control ? (
					<PlayCircle
						onClick={playVidHandler}
						className="play"
						color="#fff"
						width="70"
						height="70"
					/>
				) : (
					<PauseCircle
						onClick={pauseVidHandler}
						className="pause"
						color="#fff"
						width="70"
						height="70"
					/>
				)}
			</HeroItemsContainer>
		</HeroSection>
	)
}

export default Hero
